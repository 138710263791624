<template>
  <div class="progress-bar-container">
    <div v-if="isSmall" class="has-fullwidth">
      <div class="is-flex is-flex-direction-row">
        <div class="progress-text is-flex-grow-1 is-small">
          {{ $t("quizzes.current_question_index", [realIndex, questionsCount]) }}
        </div>
        <div class="progress-text has-text-primary is-small">{{ percentage }}%</div>
      </div>
      <div class="is-flex has-fullwidth">
        <progress class="progress progress-bar-progress is-primary is-small is-flex-grow-1" :value="percentage"
                  max="100"></progress>
      </div>
    </div>
    <div v-else class="columns is-mobile is-vcentered is-variable is-1">
      <div class="column is-narrow">
        <div class="progress-text">
          {{ $t("quizzes.current_question_index", [realIndex, questionsCount]) }}
        </div>
      </div>
      <div class="column">
        <progress class="progress is-primary is-small" :style="progressBarStyle" :value="percentage"
                  max="100"></progress>
      </div>
      <div class="column is-narrow">
        <div class="progress-text has-text-primary">{{ percentage }}%</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "QuizProgressBar",

  components: {},

  props: ["currentIndex", "questionsCount", "isSmall"],

  computed: {
    percentage() {
      return Math.round((this.realIndex / this.questionsCount) * 100);
    },

    realIndex() {
      return this.currentIndex;
    },

    progressBarStyle() {
      return {
        height: "8px",
        width: "238px",
      };
    },
  },
};
</script>
