<template>
  <div class="container has-fullheight">
    <div v-if="isFinished">
      <div v-if="isRequesting">
        <b-loading :active="true" :is-full-page="false"></b-loading>
      </div>
      <quiz-final-results
        :quiz-results="results"
        :visible-results="visibleResults"
        :quiz-type="quizType"
        :is-small="isSmall"
        @return="$emit('return')"
        v-else
      ></quiz-final-results>
    </div>
    <div v-else-if="quiz">
      <div class="columns is-mobile is-vcentered is-gapless mb-1">
        <div class="column is-narrow mr-1">
          <div class="quiz-icon-container">
            <component :is="quizIcon" class="has-fill-primary"></component>
          </div>
        </div>
        <div class="column">
          <div class="quiz-type-title" :class="smallClass">
            <p v-if="isSmall">{{ title }}</p>
            <p v-else>{{ type }}</p>
          </div>
        </div>
      </div>
      <div class="quiz-title has-text-primary" v-if="!isSmall">{{ title }}</div>
      <quiz-progress-bar
        class="quiz-progress-bar"
        :current-index="currentQuestionIndex"
        :questions-count="questionCount"
        :is-small="isSmall"
      ></quiz-progress-bar>
      <template v-if="currentQuestion">
        <quiz-question
          ref="quizQuestion"
          :quiz-question="currentQuestion"
          :current-responses="currentResponses"
          :is-small="isSmall"
        ></quiz-question>
        <div class="quiz-buttons-container">
          <div
            class="button is-rounded has-text-white previous-button mr-2"
            :class="smallClass"
            @click="onPreviousButtonClick"
            v-if="!isFirstQuestion"
          >
            {{ $t("common.back") }}
          </div>
          <div class="button is-rounded is-primary" :class="smallClass" @click="tryToSubmitQuiz" v-if="isLastQuestion">
            {{ $t("common.finish") }}
          </div>
          <div class="button is-rounded is-primary" :class="smallClass" @click="onNextButtonClick" v-else>
            {{ $t("common.next") }}
          </div>
        </div>
      </template>
    </div>
    <div v-else class="is-flex is-justify-content-center is-align-items-center has-fullheight">
      <list-loading-indicator :active="true" v-if="isAnyLoading"></list-loading-indicator>
      <div v-else>
        {{ $t("common.not_found") }}
      </div>
    </div>
  </div>
</template>

<script>
import QuizProgressBar from "@/web/components/quizzes/QuizProgressBar";
import QuizQuestion from "@/web/components/quizzes/QuizQuestion";
import QuizFinalResults from "@/web/components/quizzes/QuizFinalResults";
import RequestMixin from "@/shared/mixins/RequestMixin";
import {mapActions, mapGetters} from "vuex";
import Constants from "@/web/constants";
import QuizIcon from "@/assets/quizzes/quiz.svg";
import SurveyIcon from "@/assets/quizzes/survey.svg";
import ListLoadingIndicator from "../../../shared/components/common/loading/ListLoadingIndicator";

export default {
  name: "QuizInProgress",

  components: {ListLoadingIndicator, QuizProgressBar, QuizQuestion, QuizFinalResults},

  mixins: [RequestMixin],

  props: {
    quizId: {
      type: Number,
      default: null,
    },

    isSmall: {
      type: Boolean,
      default: false,
    },
  },

  requests: {
    async submit() {
      await this.submitQuiz({
        componentId: this.componentId,
        quizId: this.quizId,
      }).then(response => {
        this.clearCurrentQuiz();
      });
    },

    async loadResults() {
      await this.loadQuizResult({componentId: this.componentId, quizId: this.quizId});
    },
  },

  watch: {
    error(newVal) {
      if (newVal) {
        this.$root.openErrorModal(newVal);
        this.error = null;
      }
    },
    quiz: {
      immediate: true,
      handler: function (newVal, oldVal) {
        if (newVal && (oldVal === null || !this.currentQuizId || newVal.id !== this.currentQuizId)) {
          this.startNewQuiz(newVal);
        }
      },
    },

    isFinished: {
      immediate: true,
      handler: function (newVal, oldVal) {
        if (newVal) {
          this.loadResults();
        }
      },
    },
  },

  computed: {
    ...mapGetters("quizzes", ["getById", "isAnyLoading", "getResult"]),
    ...mapGetters("quizAnswers", [
      "currentQuizId",
      "isFirstQuestion",
      "isLastQuestion",
      "currentQuestion",
      "currentQuestionIndex",
      "questionCount",
      "currentResponses",
      "allResponses",
    ]),

    quiz() {
      return this.getById(this.quizId);
    },

    componentId() {
      return this.quiz.event_component_id;
    },

    type() {
      if (this.quiz.mode === Constants.QUIZ_MODE.QUIZ) {
        return this.$t("quizzes.quiz");
      } else {
        return this.$t("quizzes.survey");
      }
    },

    isFinished() {
      return this.quiz && this.quiz.is_completed_by_me;
    },

    results() {
      return this.getResult(this.quizId);
    },

    quizType() {
      return this.quiz.mode;
    },

    visibleResults() {
      return this.quiz.visible_results;
    },

    title() {
      return this.quiz.name;
    },

    quizIcon() {
      if (this.quiz.mode === Constants.QUIZ_MODE.QUIZ) {
        return QuizIcon;
      } else {
        return SurveyIcon;
      }
    },

    smallClass() {
      if (this.isSmall) {
        return "is-small";
      } else {
        return "";
      }
    },
  },

  methods: {
    ...mapActions("quizzes", ["loadQuizzes", "submitQuiz", "loadQuizResult"]),
    ...mapActions("quizAnswers", [
      "startNewQuiz",
      "clearCurrentQuiz",
      "nextQuestion",
      "previousQuestion",
      "setResponses",
      "checkIfAllQuestionsAreAnswered",
      "navigateToUnansweredQuestion",
    ]),

    onPreviousButtonClick() {
      const responses = this.$refs.quizQuestion.getAllResponses();
      this.setResponses(responses);
      this.previousQuestion();
    },

    onNextButtonClick() {
      const responses = this.$refs.quizQuestion.getAllResponses();
      this.setResponses(responses);
      this.nextQuestion();
    },

    tryToSubmitQuiz() {
      const responses = this.$refs.quizQuestion.getAllResponses();
      this.setResponses(responses);
      this.checkIfAllQuestionsAreAnswered().then(areAnswered => {
        if (areAnswered) {
          this.submit();
        } else {
          this.showUnansweredQuestionError();
          this.navigateToUnansweredQuestion();
        }
      });
    },

    showUnansweredQuestionError() {
      const err = this.$t("quizzes.quiz_unanswered_question_error");
      this.$root.openErrorModal(err);
    },
  },
};
</script>

<style lang="scss" scoped></style>
